import React, { useState } from 'react';
import { Modal, Input, Form } from 'antd';
import AdminAxios from '../../axios';

const ChangePasswordModal = (props: any) => {
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        if (newPassword) {
            setLoading(true);
            await AdminAxios.post('/api/admin/ChangeUserPassword', {
                username: props.username,
                newPassword: newPassword
            }).then(response => {
                props.onClose();
            }).catch(err => {

            }).then(() => {
                setLoading(false);
            })
        }
    }

    return (
        <Modal
            open={props.visible}
            onOk={handleSave}
            okText='Save'
            confirmLoading={loading}
            onCancel={props.onClose}
            cancelButtonProps={{ disabled: loading }}
        >
            <Form layout='inline'>
                <Form.Item label='New Password'>
                    <Input type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default ChangePasswordModal;