import React from 'react';
import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login/Login';
import Board from './Board/Board';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/board' element={<Board />} />
        <Route path='/login' element={<Login />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;
