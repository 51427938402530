import React, { useState, useEffect } from 'react';
import UserTable from './UserTable/UserTable';
import { Button } from 'antd';
import './Board.css';
import UserDetailModal from './UserDetail/UserDetail';
import AdminAxios from '../axios';
import { useNavigate } from 'react-router';

type BoardProps = {

}

const Board = (props: BoardProps) => {
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const loadUsers = async () => {
        await AdminAxios.get('/api/admin/GetUsers').then(response => {
            if (response && response.data) {
                let usersWithKey = response.data.map((user: { userId: string; }) => {
                    return {
                        ...user,
                        key: user.userId
                    }
                })
                setUsers(usersWithKey);
            }
        });
    }

    useEffect(() => {
        if (sessionStorage.getItem('enote-admin')) {
            loadUsers();
        }
    }, []);

    const logout = () => {
        sessionStorage.removeItem('enote-admin');
        navigate('/', { replace: true })
    }

    if (!sessionStorage.getItem('enote-admin')) {
        navigate('/', { replace: true })
    }

    const onUserDetailUpdate = (userId: number) => {
        setSelectedUserId(userId);
        loadUsers();
    }

    return (
        <div className='Board'>
            <div className='UserDetailControlDiv'>
                <Button type='primary' onClick={() => setSelectedUserId(0)}>New User</Button>
                <Button type='default' onClick={logout}>Sign Out</Button>
                <UserDetailModal userId={selectedUserId} onUpdate={onUserDetailUpdate}/>
            </div>
            <div className='UserTableDiv'>
                <UserTable users={users} onUserSelect={(userId : number) => setSelectedUserId(userId)}/>
            </div>

        </div>
    )
}

export default Board;