import React, { useState, FormEvent, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router';
import AdminAxios from '../axios';
import { LockOutlined, UserOutlined} from '@ant-design/icons';
import './Login.css';

type LoginProps = {

}

const Login = (props : LoginProps) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e: FormEvent) => {
        setLoading(true);
        await AdminAxios.post('/api/admin/LoginAdmin', {
            username: username,
            password: password
        }).then(response => {
            if (response.data) {
                sessionStorage.setItem('enote-admin', response.data);
                setLoggedIn(true);
            }
        }).catch(err => {
        }).then(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (loggedIn && sessionStorage.getItem('enote-admin')) {
            navigate('/board')
        }
    }, [loggedIn, navigate])



    return (
        <div className='Login'>
            <Form onFinish={handleSubmit} className="login-form" autoComplete='off'>
                <Form.Item>
                    <Input
                        autoComplete='off'
                        prefix={<UserOutlined />}
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Input
                        autoComplete='off'
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Login;