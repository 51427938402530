import React, { useState } from 'react';
import { Modal, Input, Form, Radio, Space } from 'antd';
import AdminAxios from '../../axios';
import dayjs from 'dayjs';

type RewnewLicenseModalProps = {
    visible: boolean,
    onClose: () => void,
    onUpdate: () => void,
    user: {
        userId: number,
        licenseExpiryDate: string,
    }
}

enum RenewAction {
    SendInvoiceOnly = 0,
    RenewOnly = 1,
    SendInvoiceAndRenew = 2
};

const RewnewLicenseModal = (props: RewnewLicenseModalProps) => {
    const [term, setTerm] = useState(1);
    const [action, setAction] = useState<RenewAction>(RenewAction.SendInvoiceOnly);

    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        if (term) {
            setLoading(true);
            await AdminAxios.post('/api/admin/RenewLicense', {
                userId: props.user.userId,
                term: term,
                action: action
            }).then(response => {
                props.onUpdate();
            }).catch(err => {

            }).then(() => {
                setLoading(false);
            })
        }
    }

    return (
        <Modal
            destroyOnClose
            open={props.visible}
            onOk={handleSave}
            okText='Save'
            confirmLoading={loading}
            onCancel={props.onClose}
            cancelButtonProps={{ disabled: loading }}
        >
            <Form layout='inline'>
                <Space direction='vertical'>
                    <Form.Item label='Current License Expiry Date:'>
                        <Input type='string' value={props.user.licenseExpiryDate} onChange={(e) => setTerm(parseInt(e.target.value))} readOnly />
                    </Form.Item>
                    <Form.Item label='New License Expiry Date:'>
                        <Input type='string' value={term > 0 ? dayjs(props.user.licenseExpiryDate).add(term, 'month').format('YYYY-MM-DD') : props.user.licenseExpiryDate} onChange={(e) => setTerm(parseInt(e.target.value))} readOnly />
                    </Form.Item>
                    <Form.Item label='Term (Month)'>
                        <Input type='number' value={term} onChange={(e) => setTerm(parseInt(e.target.value))} />
                    </Form.Item>
                    <Form.Item>
                        <Radio.Group value={action} onChange={e => setAction(parseInt(e.target.value))}>
                            <Space direction='vertical'>
                                <Radio value={RenewAction.SendInvoiceOnly}>Send invoice only</Radio>
                                <Radio value={RenewAction.RenewOnly}>Renew license only</Radio>
                                <Radio value={RenewAction.SendInvoiceAndRenew}>Send invoice and renew license</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Space>

            </Form>
        </Modal>
    )
}

export default RewnewLicenseModal;