import axios from 'axios';
import { notification } from 'antd';

const AdminAxios = axios.create();

AdminAxios.interceptors.request.use(request => {
    let token = sessionStorage.getItem('enote-admin');
    request.headers["Authorization"] = "bearer " + token;

    return request;
}, error => {
    console.log(error);
});

AdminAxios.interceptors.response.use((response) => {
    if (response && response.data && response.data.message) {
        notification.success({
            message: 'Success',
            description: response.data.message
        });
    }
    return response;
}, error => {
    try {
        if (error && error.response) {
            if (error.response.status === 401) {
                console.log('Unauthorized Access. Returning to login page.');
                sessionStorage.removeItem('enote-admin');
                if (window.location.pathname !== '/login') {
                    notification.error({
                        message: 'Session Expired',
                        description: 'Redirecting to Login Page in 5 seconds...'
                    });
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 5000);
                    return;
                }
            }
            
            if (error.response && error.response.data && !error.response.data.confirmation && (typeof error.response.data === 'string')) {
                console.log(error.response);
                notification.error({
                    message: 'Failed',
                    description: error.response.data
                });
            }
        } else if (!error || !error.status) {
            notification.error({
                message: 'Network Failure',
                description: 'Failed to communicate with the server. Please try again later.'
            });
        }
    } catch (uncaughtErr) {
        notification.error({
            message: 'Failed',
            description: 'An unexpcted error occurred'
        });
    }

    return Promise.reject(error);
});

export default AdminAxios;
