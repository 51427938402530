import React from 'react';
import { Table } from 'antd';
import './UserTable.css';
import dayjs from 'dayjs';

type UserTableProps = {
    users: Array<any>
    onUserSelect: (userId : number) => void
}

const UserTable = (props: UserTableProps) => {

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'userId',
        },
        {
            title: 'Username',
            dataIndex: 'username',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
        },
        {
            title: 'Organisation',
            dataIndex: 'organisationID',
            render: (text: string, r: any, index: number) => {
                let organisation = '';
                if (r && typeof r.organisationId === 'number') {
                    let organisationId = parseInt(r.organisationId);
                    if (organisationId === 1) {
                        organisation = 'NZRA';
                    }
                    else if (organisationId === 2) {
                        organisation = 'NZASA';
                    }
                }
                return <span>{organisation}</span>
            }
        },
        {
            title: 'Expiry Date',
            dataIndex: 'licenseExpiryDate',
            render: (text: string, r: any, index: number) => {
                let date = '';
                if (r) {
                    date = dayjs(r.licenseExpiryDate).format('YYYY-MM-DD');
                }
                return <span>{date}</span>
            }
        }
    ];

    return (
        <div className='UserTableContainer'>
            <Table
                columns={tableColumns}
                dataSource={props.users} 
                onRow={ (record, index) => {
                    return {
                        onClick: e => {
                            if (record) {
                                props.onUserSelect(record.userId);
                            }
                        }
                    }
                }}
                />
        </div>
    );
}

export default UserTable;