import React, { useState, useEffect } from 'react';
import { Input, Form, Radio, DatePicker, Button } from 'antd';
import AdminAxios from '../../axios';
import './UserDetail.css';
import ChangePasswordModal from '../ChangePassword/ChangePassword';
import RewnewLicenseModal from '../RenewLicense/RenewLicenseModal';
import dayjs from 'dayjs';

const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 10 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 14 }
    },
};

type UserDetailProps = {
    userId: number,
    onUpdate: (userId: number) => void
}

const UserDetailModal = (props: UserDetailProps) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [vendorID, setVendorId] = useState("");
    const [providerId, setProviderId] = useState("");
    const [accRate, setAccRate] = useState(0);
    const [email, setEmail] = useState("");
    const [homePhone, setHomePhone] = useState("");
    const [workPhone, setWorkPhone] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [weChat, setWechat] = useState("");
    const [organisationId, setOrganisationID] = useState(1);
    const [accountStatus, setAccountStatus] = useState(1);
    const [licenseExpiryDate, setLicenseExpiryDate] = useState(dayjs().add(30, 'days').format('YYYY-MM-DD'));
    const [paymentRate, setPaymentRate] = useState(0);
    //const [physicalAddress, setPhysicalAddress] = useState('');

    const [loading, setLoading] = useState(false);

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showRenewLicenseModal, setShowRenewLicenseModal] = useState(false);

    useEffect(() => {
        (async () => {
            await loadUser(props.userId)
        })()
    }, [props.userId])

    const handleSave = async () => {

        const userDetail = {
            userId: props.userId,
            username: username,
            firstName: firstName,
            lastName: lastName,
            gstNumber: gstNumber,
            companyName: companyName,
            vendorID: vendorID,
            providerId: providerId,
            accRate: accRate,
            email: email,
            homePhone: homePhone,
            workPhone: workPhone,
            mobilePhone: mobilePhone,
            weChat: weChat,
            organisationId: organisationId,
            accountStatus: accountStatus,
            licenseExpiryDate: licenseExpiryDate,
            paymentRate: paymentRate
        }

        if (props.userId === 0) {
            if (username && password) {
                setLoading(true);
                await AdminAxios.post('/api/admin/RegisterUserByAdmin', {
                    userDetail: userDetail,
                    password: password
                }).then(response => {
                    props.onUpdate(response.data.userId);
                }).catch(err => {
                }).then(() => {
                    setLoading(false);
                })
            }
        } else {
            setLoading(true);
            await AdminAxios.post('/api/admin/SaveUserDetail', userDetail).then(response => {
                console.log(response);
                props.onUpdate(props.userId);
            }).catch(err => {
            }).then(() => {
                setLoading(false);
            })

        }

    }

    const handleUpdate = async () => {
        setShowPasswordModal(false);
        setShowRenewLicenseModal(false);
        await loadUser(props.userId);
        props.onUpdate(props.userId);
    }

    const loadUser = async (userId: number) => {
        let user: any = {};
        if (userId !== 0) {
            setLoading(true);
            await AdminAxios.get('/api/admin/GetUserDetail', {
                params: {
                    userId: userId
                }
            }).then(response => {
                if (response && response.data) {
                    user = response.data;
                    setUsername(user.username || "");
                    setFirstName(user.firstName || "");
                    setLastName(user.lastName || "");
                    setGstNumber(user.gstNumber || "");
                    setCompanyName(user.companyName || "");
                    setVendorId(user.vendorId || "");
                    setProviderId(user.providerId || "");
                    setAccRate(typeof user.accRate === 'number' ? parseFloat(user.accRate) : 0);
                    setEmail(user.email || "");
                    setHomePhone(user.homePhone || "");
                    setWorkPhone(user.workPhone || "");
                    setMobilePhone(user.mobilePhone || "");
                    setWechat(user.weChat || "");
                    setOrganisationID(user.organisationId);
                    setAccountStatus(user.accountStatus);
                    setLicenseExpiryDate(user.licenseExpiryDate || dayjs().add(30, 'days').format('YYYY-MM-DD'));
                    setPaymentRate(user.paymentRate || 0);
                    //setPhysicalAddress(user.physicalAddress || "");
                }
            }).catch(err => {

            }).then(() => {
                setLoading(false);
            })
        } else {
            setUsername("");
            setFirstName("");
            setLastName("");
            setGstNumber("");
            setCompanyName("");
            setVendorId("");
            setProviderId("");
            setAccRate(0);
            setEmail("");
            setHomePhone("");
            setWorkPhone("");
            setMobilePhone("");
            setWechat("");
            setOrganisationID(1);
            setAccountStatus(1);
            setLicenseExpiryDate(dayjs().add(30, 'days').format('YYYY-MM-DD'));
            setPaymentRate(0);
            //setPhysicalAddress("");
        }
    }

    return (
        <div>
            <Button type='primary' onClick={handleSave} loading={loading}>Save</Button>
            <Button type='default' onClick={() => setShowPasswordModal(true)} disabled={props.userId === 0 || loading}>Change Password</Button>
            <Button type='default' onClick={() => setShowRenewLicenseModal(true)} disabled={props.userId === 0 || loading}>Renew & Invoice</Button>
            <Form layout='horizontal' {...formItemLayout} className='UserForm'>
                <Form.Item label='User ID'>
                    <Input name='userID' value={props.userId} readOnly />
                </Form.Item>
                <Form.Item label='Username'>
                    <Input autoComplete="off" name='username' value={username} onChange={(e) => setUsername(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                {
                    props.userId === 0 ?
                        (
                            <Form.Item label='Password'>
                                <Input type='password' name='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Form.Item>
                        ) : null
                }
                <Form.Item label='First Name'>
                    <Input autoComplete="off" name='firstName' value={firstName} onChange={(e) => setFirstName(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                <Form.Item label='Last Name'>
                    <Input autoComplete="off" name='lastName' value={lastName} onChange={(e) => setLastName(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                <Form.Item label='GST Number'>
                    <Input name='gstNumber' value={gstNumber} onChange={(e) => setGstNumber(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                <Form.Item label='Company'>
                    <Input autoComplete="off" name='companyName' value={companyName} onChange={(e) => setCompanyName(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                <Form.Item label='Vendor ID'>
                    <Input autoComplete="off" name='vendorID' value={vendorID} onChange={(e) => setVendorId(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                <Form.Item label='Provider ID'>
                    <Input autoComplete="off" name='providerID' value={providerId} onChange={(e) => setProviderId(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                <Form.Item label='Home Phone'>
                    <Input autoComplete="off" name='homePone' value={homePhone} onChange={(e) => setHomePhone(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                <Form.Item label='Mobile Phone'>
                    <Input autoComplete="off" name='mobilePhone' value={mobilePhone} onChange={(e) => setMobilePhone(e.target.value)} readOnly={props.userId > 0} />
                </Form.Item>
                <Form.Item label='Acc Rate'>
                    <Input type='number' name='accRate' value={accRate} onChange={(e) => setAccRate(parseFloat(e.target.value))} />
                </Form.Item>
                <Form.Item label='Payment Rate'>
                    <Input type='number' name='paymentRate' value={paymentRate} onChange={(e) => setPaymentRate(parseFloat(e.target.value))} />
                </Form.Item>
                <Form.Item label='License Expiry Date'>
                    <DatePicker onChange={(date) => setLicenseExpiryDate(date ? date.format('YYYY-MM-DD') : '')} value={dayjs(licenseExpiryDate)} allowClear={false} />
                </Form.Item>
                <Form.Item label='Organisation'>
                    <Radio.Group defaultValue={1} value={organisationId} disabled={props.userId > 0}>
                        <Radio.Button value={1} onChange={(e) => setOrganisationID(e.target.value)} checked={organisationId === 1}>NZRA</Radio.Button>
                        <Radio.Button value={2} onChange={(e) => setOrganisationID(e.target.value)} checked={organisationId === 2}>NZASA</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='Account Status'>
                    <Radio.Group defaultValue={accountStatus} value={accountStatus}>
                        <Radio.Button value={1} onChange={(e) => setAccountStatus(e.target.value)} checked={accountStatus === 1}>Active</Radio.Button>
                        <Radio.Button value={0} onChange={(e) => setAccountStatus(e.target.value)} checked={accountStatus === 0}>Disabled</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Form>
            <ChangePasswordModal visible={showPasswordModal} username={username} onClose={() => setShowPasswordModal(false)} />
            {
                showRenewLicenseModal &&
                <RewnewLicenseModal
                    visible={showRenewLicenseModal}
                    user={{
                        userId: props.userId,
                        licenseExpiryDate: dayjs(licenseExpiryDate).format('YYYY-MM-DD')
                    }}
                    onUpdate={handleUpdate}
                    onClose={() => setShowRenewLicenseModal(false)} />
            }
        </div>
    )
}

export default UserDetailModal;